.signup_background{
  min-height: 100vh !important;
  min-width: 100vw !important;
  background-image: url("../../img/signup.jpg") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;  
}
.text_white{
  color: #FFF  !important;
  display: flex !important;
    
}
.signup_row{
min-height: 100vh !important;
display: flex !important;
justify-content: center !important;
align-items: center !important;
width:100%;
}

.signup_left_col{
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;

}
.signup_left_col h1{
 font-size: 56px !important;
}

.signup_text p{
margin: 20px 0px !important;
font-size: 26px !important;
text-align: center !important;
}

.filled_sigup_button{
border-radius: 0px  !important;
color:#5FC7F1 !important;
background-color: #FFF !important;
border-color: #fff !important;
font-size: 20px !important;
padding: 20px 120px  !important;

}

.filled_sigup_button:hover{
background-color: #000 !important;
color: #FFF !important;
border-color:  #000 !important;

}
.signup_right_col_flex{
display: flex !important;
justify-content: center !important;
align-items: center !important;
}
.signup_right_col{
width:100% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;

}
.signup_right_col h1{
  font-size: 69px !important;
  color: #5FC7F1 !important;
}

.signupForm{
width: -webkit-fill-available !important;
/* width:500px !important; */
margin: 20px 0px !important;
font-size: 26px !important;
text-align: center !important;
max-width:457px !important;
}
/* .container_signup_form{
margin: 10px;
display: flex;
justify-content: space-between;
} */

.form-control{
border-radius: 0px !important;
border:#000 !important;
height: 63px !important;
background-color: #3E3E3E !important;
color: #FFF !important;
position:relative;
}
.passwordEye{
position: relative !important;
}
/* .my-4{
position:relative;
float: left;

margin-top: 6px;
} */
/* responsive  */

/* @media screen and (max-width: 600px) {
.my-4 {
  width: 100%;
  margin-top: 0;

}
} */

.eye{
  position: absolute !important;
  right: 15px;
  bottom: 15px;
}

.filled_signup_button{
width: 100% !important;
  border-radius: 0px  !important;
  background-image: linear-gradient(to bottom , #5FC7F1, #4B92CE)  !important;
  background-color: #FFF !important;
  border:none  !important;
  color: #FFF !important;
  font-size: 20px !important;
  padding: 20px 120px  !important;
}
.filled_signup_button:hover{
  background-color: #000 !important;
}

.divider{
display: flex !important;
justify-content:center  !important ;
align-items:center  !important;
margin: 15px 0px  !important;
width: 100% !important; 
/* border: 1px solid #fff !important; */
}
.divider hr {
width: 210px !important; 
border: 1px solid #fff !important;

}
.divider .or {
margin: 0px 12px  !important;
}
.social_media_buttons{

display: flex !important;
justify-content: center!important;
align-items: center !important;
margin: 20px 0px !important;
width: -webkit-fill-available !important;
width: 100%!important;
}
.outlined_sigin_button{
border-radius: 0px  !important;
border-color: #fff !important;
font-size: 20px !important;
padding: 15px 99px  !important;
background-color: #000 !important;
color: #FFF !important;
}
.outlined_sigin_button:hover{
border-radius: 0px  !important;
color:#5FC7F1 !important;
background-color: #FFF !important;
border-color: #fff !important;
}
