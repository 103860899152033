.home_image{
    background-color: var(--secondry-color);
    min-height:100vh !important;
    min-width:40%!important;
    background-image: url("../../img/iPadMini.png")!important;
    background-repeat: no-repeat!important;
    background-position: 80% center!important;
}
.main_screen_container{
  
 margin:0px 100px 0px 100px !important;
 color: var(--white-color) !important;
 display: flex !important;
 flex-direction: column !important;
}
.text_white{
color:  var(--white-color) !important;
}
.join_us_lable{
font-size: 84px !important;
margin-top: 60px  !important;
margin-bottom: 20px !important;
}
li{
    font-size: 20px !important;
}
.social_media_button{
    border-color: var(--white-color) !important;
    border-radius: 8px !important;
    color: var(--white-color) !important;
}
.social_media_button:hover{
    background-color: var(--white-color) !important;   
    color: var(--secondry-color) !important;
}
 
.list div {
    font-style: italic !important;
    margin: 8px 0px !important;;
}
.join_us_button{
    margin: 30px 0px !important;
}
.availability{
    margin: 20px 0px !important;
}
.social_bottom{
    max-width: 300px !important;
    margin-top: 20px !important;
    margin-bottom: 20px!important;
    display: flex!important;
    justify-content: space-between!important;
}
 
@media screen and (max-width: 768px) {
    .home_image{
        background-image:  none!important; 
    }}