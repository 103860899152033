.signin_background {
  min-height: 100vh !important;
  min-width: 100vw !important;
  background-image: url("../../img/signin.jpg") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  margin: auto;
}

.text_white {
  color: #fff !important;
}

.signin_row {
  min-height: 100vh !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.left_col_flex {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.left_col {
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}

.left_col h1 {
  font-size: 69px !important;
  color: #5fc7f1 !important;
}

.left_col .lable {
  font-size: 65px !important;
  color: #5fc7f1 !important;
  font-weight: 600;
}

.signupForm {
  width: -webkit-fill-available !important;
  margin: 20px 0px !important;
  font-size: 26px !important;
  max-width: 457px !important;
  text-align: center !important;
}

.divider {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 15px 0px !important;
}

.divider hr {
  width: 210px !important;
  border: 1px solid #fff !important;
}

.divider .or {
  margin: 0px 12px !important;
}

.social_media_buttons {
  margin: 10px 0px !important;
  display: flex !important;
  justify-content: space-between !important;
}

.outlined_sigin_button {
  border-radius: 0px !important;
  border-color: #fff !important;
  font-size: 20px !important;
  padding: 15px 99px !important;
  background-color: #000 !important;
  color: #fff !important;
}

.outlined_sigin_button:hover {
  border-radius: 0px !important;
  color: #5fc7f1 !important;
  background-color: #fff !important;
  border-color: #fff !important;
}

.right_col {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}

.right_col h1 {
  font-size: 56px !important;
}

.right_signin_text p {
  margin: 20px 0px !important;
  font-size: 26px !important;
  text-align: center !important;
}

.filled_sigin_button {
  border-radius: 0px !important;
  color: #5fc7f1 !important;
  background-color: #fff !important;
  border-color: #fff !important;
  font-size: 20px !important;
  padding: 20px 120px !important;
}

.filled_sigin_button:hover {
  background-color: #000 !important;
  color: #fff !important;
  border-color: #000 !important;
}

.pointer_cursor {
  cursor: pointer;
}

.form-control {
  border-radius: 0px !important;
  border: #000 !important;
  font-size: 18px;
  height: 63px !important;
  background-color: #3e3e3e !important;
  color: #fff !important;
}

.passwordEye {
  position: relative !important;
}

.forgot_eye {
  position: absolute !important;
  right: 15px !important;
  top: 10px !important;
  cursor: pointer;
}

.filled_signup_button {
  width: 100% !important;
  border-radius: 0px !important;
  background-image: linear-gradient(to bottom, #5fc7f1, #4b92ce) !important;
  color: #fff !important;
  font-size: 20px !important;
  padding: 20px 0px !important;
}

.filled_signup_button:hover {
  background: #000 !important;
  color: #5fc7f1 !important;
  border-color: #5fc7f1 !important;
}

.signin_background .apple-logo,
.signup_background .apple-logo {
  height: 55px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid;
  border-color: var(--primary-light-color);
  cursor: pointer;
  background-color: black;
}
