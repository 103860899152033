:root {
    --primary-color: #4B92CE !important;
    --primary-light-color: #5FC7F1 !important;

    --secondry-color: #000000 !important;
    --secondry-light-color: #181818 !important;
    --dark-grey-color: #212121 !important;
    --light-grey-color: #3E3E3E !important;
    --light-color: #606060 !important;

    --white-color: #ffffff !important;
    --white-grey-color: #aaaaaa !important;

      }
  