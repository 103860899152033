.selected {
    text-decoration: none !important;
    border-bottom: 2px solid #5FC7F1 !important;
    padding: 10px 20px !important;
    color: #5FC7F1 !important; 
}

.unselected {
    text-decoration: none !important;
    color: #FFF !important;  
    padding: 10px 20px !important;
  
}