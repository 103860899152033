  /* .eq_card {
   background:#181818; 
   border: 1px solid #181818;
  }
 
  .video {
    width: auto;
    margin-bottom: 30px;
  }
 
  .thumbnail    {
    width: 100%;
    position: relative;
    display: block;
    flex: none;
    min-Height: 200px;
  }
  .thumbnail_img {
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    display: block;
  }
  .thumb{
    display: block;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .thumb img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

 
  .author img  {
    object-fit: cover;
    border-radius: 50%;
    max-height: 50px;
    max-width: 50px;
    margin-right: 10px;
  }
  .details {
    width: 100%;
    display: flex;
    height: 100px;
  }
  .title {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
.video_title{
  color: #FFF ;
  font-size: 13px;
  width: 80%;
  display: inline-block;
  font-weight: 600;
 
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.channel_text_color{
    color: #AAAAAA;
  }
  .title h3 {
    color: rgb(3, 3, 3);
    line-height: 18px;
    font-size: 14px;
    margin-bottom: 6px;
  }
  .title a,
  span {
    text-decoration: none;
    color: rgb(96, 96, 96);
    font-size: 12px;
  }
  .video{
    background:#181818; 
    border: 1px solid #181818;
    margin-bottom: 20px;
  }

.eq_vedio_card{
  height: 200px;
}

  /* copy paste by youtube */

  

  .video{
    /* margin-right: 4px; */
    display: inline-block;
    width: 300px;
    margin-bottom: 24px;
    position: relative;
  }
  .thumbnail{
    background:#070303;  
    height: 170px;
    width: 100%;
    display: block;
    position: relative;
    flex: none;
}
.eq_blur{
   width: 100%;
   height: 100%;
  text-align: center; 
  background-color: #000000;
  position: relative;
  overflow: hidden;
}
.eq_blur_bg{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 360px;
  height: 203px;
  position: absolute;
  filter: blur(8px);
  -webkit-filter: blur(8px);
  border-radius: 4px;
}
.thumbnail_img{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  display: block;
  height: 100%;
}
.thumb{
  /* background-color: transparent; */
  display: block;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 0;
    width: 100%;
    height: 100%;
}

.image_thumb{    
  width: 100%;
  height: 100%;
  object-fit: contain;
} 

.time{
  background-color: #000000;
  position: absolute;
  z-index:4;
  bottom: 6px;
  right: 6px;
}
 /* bottom */
.details {
  width: 100%;
  display: flex;
  height: 100px;
}
.author img {
  object-fit: cover;
  border-radius: 50%;
  max-height: 50px;
  max-width: 50px;
  margin-right: 10px;
}
.title {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.video_title{
color: #FFF ;
font-size: 13px;
width: 80%;
display: inline-block;
font-weight: 600;
overflow: hidden !important;
text-overflow: ellipsis;
}
.channel_text_color{
  color: #AAAAAA;
}
.title h3 {
  color: rgb(3, 3, 3);
  line-height: 18px;
  font-size: 14px;
  margin-bottom: 6px;
}
.title a,
span {
  text-decoration: none;
  color: rgb(96, 96, 96);
  font-size: 12px;
}

/* suggestion card */

.suggest{
  background:#070303;  
  width: "168px";
  height: "100px";
  display: block;
  position: relative;
  flex: none;
}

.eq_card svg {
  width: 2.5rem;
  height: 1.5rem;
  margin-top: 1rem;
  cursor: pointer;
}