.edit-video-modal .label {
    color: #ffffff;
    text-align: left;
}

.edit-video-modal .editVideoForm .element {
    height: 2rem !important;
    width: 100% !important;
}

.edit-video-modal .dropdown-container {
    width: 100%;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    appearance: none;
    border: none !important;
    border-radius: 0px !important;
    background-color: #3E3E3E !important;
    color: #FFF !important;
}

.edit-video-modal .dropdown-container .dropdown-heading-value span {
    color: #FFF !important;
}

.edit-video-modal .mr-1 {
    margin-right: 1rem;
}

.edit-video-modal .multi-field .dropdown-container .dropdown-heading {
    height: 63px !important;
}

.edit-video-modal .multi-field .dropdown-container .dropdown-heading-value span {
    font-size: 18px;
    color: #757575 !important;
}

.edit-video-modal .form-control.grey,
.textarea-control.grey_disable,
.grey_disable {
    color: #757575 !important;
}

.edit-video-modal .multi-field.white .dropdown-container .dropdown-heading-value span {
    color: #fff !important;
    font-size: 18px;
}

.edit-video-modal .multi-field.white .dropdown-container .dropdown-heading-value .gray {
    color: gray !important;
}

.edit-video-modal .more_img_wrapper {
    cursor: pointer;
}

.edit-video-modal .more_img_wrapper .more_img {
    width: 8px;
}

.edit-video-modal .more_img_wrapper .more_img.transform {
    transform: rotate(90deg);
}

.edit-video-modal .border-bot {
    border-bottom: 1px solid #ffffff;
}

/* Remove the border style from the last child */
.edit-video-modal .border-bot:last-child {
    border-bottom: none;
}

.edit-video-modal .managed_link_wrapper {
    max-height: 13rem;
    height: 12rem;
    overflow-y: scroll;
}

.edit-video-modal .expand .managed_link_wrapper {
    max-height: 20rem;
    height: 18rem;
}

.edit-video-modal .expand .managed_link_wrapper.h-inc {
    height: 20rem;
}

.react-datepicker-wrapper {
    width: 96%;
}

.edit-video-modal .managed_link_wrapper::-webkit-scrollbar {
    width: 12px;
    /* Adjust the width as needed */
}

.edit-video-modal .managed_link_wrapper::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Color of the scrollbar thumb */
    border-radius: 6px;
    /* Rounded corners of the thumb */
}

.edit-video-modal .managed_link_wrapper::-webkit-scrollbar-track {
    background-color: #555;
    /* Color of the scrollbar track */
}

.edit-video-modal .remove_btn {
    height: 30px;
    padding: 0 30px;
    color: #ffffff;
}

.edit-video-modal .w-40r {
    width: 40rem !important;
}

.resend-email-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30rem;
}

.w-30 {
    width: 30% !important;
}

.embedLink {
    line-height: normal;
    background-color: transparent;
    border: 1px solid;
    border-radius: 5px;
    padding: 7px 4px;
    margin: 2px 4px;
    cursor: pointer;
    font-weight: bold;
    font-size: 0.8em;
}

.managed-link-form #session-date {
    display: inline-block;
    position: relative;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

.input-wrapper input[type="datetime-local"]:before {
    content: 'Never';
    color: #757575 !important;
    width: 100%;
}

.input-wrapper input[type="datetime-local"]:active:before,
.input-wrapper input[type="datetime-local"]:hover:before,
.input-wrapper input[type="datetime-local"]:visited:before,
.input-wrapper input[type="datetime-local"]:focus:before {
    content: 'Never';
    color: #757575 !important;
    width: 100%;
}