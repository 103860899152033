   /* Card styles */
   .eq_skeleton_card{
    
    height: auto;
    max-width: auto;
    overflow: hidden;
    margin-bottom: 30px;
    border-radius: 0px;
    box-shadow: 9px 17px 45px -29px rgba(0, 0, 0, 0.44);
}

/* Card image loading */
.eq_skeleton_card_image img {
    width: 100%;
    height: 100%;
}
.eq_skeleton_card_image.loading {
    height: 200px;
     width: 400px;
}
/* Card title */
.eq_skeleton_card_title {
    font-size: 22px;
    font-weight: 700;
}
.eq_skeleton_card_title.loading {
    height: 1rem;
    width: 70%;
    margin: 1rem;
    border-radius: 2px;
}
/* ICON*/
.eq_skeleton_card_icon {
    /* font-size: 16px; */  
}
.eq_skeleton_card_icon.loading {
/*     
    height: 1rem;
    width: 70%;
    margin: 1rem;
    border-radius: 2px; */
}

/* Card channelname */
.eq_skeleton_card_channelname {
    font-size: 16px;
}
.eq_skeleton_card_channelname.loading {
    height: 1rem;
    width: 30%;
    margin: 0.3rem 1rem;
    border-radius: 2px;
}
/* Card description */
.eq_skeleton_card_description {
    font-size: 16px;
}
.eq_skeleton_card_description.loading {
    height: 1rem;
    width: 50%;
    margin: 0.3rem 1rem;
    border-radius: 2px;
}
/* The loading Class */
.loading {
    position: relative;
    background:#181818; 
}
/* The moving element */
.loading::after {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
     margin-left: 70px;
    height: 100%;
    transform: translateX(-100%);
    background: -webkit-gradient(linear, left top,
                right top, from(transparent), 
                color-stop(rgba(255, 255, 255, 0.2)),
                to(transparent));
                  
    background: linear-gradient(90deg, transparent,
            rgba(131, 131, 131, 0.2), transparent);

    /* Adding animation */
    animation: loading 0.8s infinite;
}
/* Loading Animation */
@keyframes loading {
    100% {
        transform: translateX(100%);
    }
}