.eq_profile_img{
    position: relative;
}
.eq_profile_edit{
   
    position: absolute;
    bottom: 10px;
    right: -1px;
}

.eq_edit{
    background-color: #5FC7F1;
}
.eq_vl{
    border-left: 1px solid #A1A1A1;
  height: 100px;
   
  
}
.blue-arrow.right {
    background-image: url(https://eqnetwork.com/home/css/images/rt_channel_arrow.png) !important;
}
.blue-arrow.down {
    background-image: url(https://eqnetwork.com/home/css/images/down_channel_arrow.png) !important;
    width: 26px !important;
    height: 20px !important;
    margin: 10px !important;
}
.blue-arrow {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
    margin-top: 8px;
}

.user-section {
    width: 500px;
    max-width: 420px;
    padding: 5pt;
    min-height: 90px;
    background: -webkit-gradient(linear, left top, left bottom, from(#C24C70), to(#641E3C));
    border-radius: 6px;
}

.user-section .follower-info {
    margin: 1px 0;
    border-color: RGBA(62,159,236,1);
    border-style: solid;
    border-width: thin;
    border-radius: 5px;
    width: 100%;
    padding: 5px;
}

.eq-card-content .video {
    margin-right: 8px;
    width: 100% !important;
}
.eq-card-content .eq_card {
    width: 170px !important;
}
.eq-card-content .thumbnail {
    height: 95px !important;
}
.eq-card-content .eq_card .details {
    display: none;
}

.channels-list {
    min-height: 20rem;
    margin-right: 15px;
}
.channel_list_li {
    padding: 25px 15px;
    border-top-right-radius: 50px!important;
    border-bottom-right-radius: 50px!important;
}
.channel_list_li h6 {
    margin-left: 0px !important;
}
.channel_list_li_text {
    width:80%;
}