@import url(./theme-colors.css);
@import url(./css/index.css);

body {
  margin: 0px !important;
  /* padding: 0px !important; */
  box-sizing: border-box !important;
}

textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
}

.signin_row .logo,
.signup_row .logo {
  padding-left: 0px !important;
  padding-top: 20px;
  width: 230px;
}

.signin_row a,
.signup_row a {
  height: 20px;
}

/* buttons */
.outlined_button {
  background: none !important;
  border-radius: 0 !important;
  border-color: var(--primary-light-color) !important;
  color: var(--primary-light-color) !important;
}

.outlined_button:hover {
  background-image: linear-gradient(to bottom,
      var(--primary-light-color),
      var(--primary-color)) !important;
  color: var(--white-color) !important;
}

.eq_outline_button {
  background: none !important;
  border-radius: 0 !important;
  border-color: var(--primary-light-color) !important;
  color: var(--primary-light-color) !important;
}

.eq_outline_button:hover {
  background-image: linear-gradient(to bottom,
      var(--primary-light-color),
      var(--primary-color)) !important;
  color: var(--white-color) !important;
}

.eq_button {
  background: transparent !important;
  border-radius: 0 !important;
  background-image: linear-gradient(to bottom,
      var(--primary-light-color),
      var(--primary-color)) !important;
  /* border-color: var(--primary-light-color)!important;  !important;   */

  border-color: var(--primary-light-color) !important;
}

.eq_button:hover {
  background: transparent !important;
  border-color: var(--primary-light-color) !important;
  color: var(--primary-light-color) !important;
}

.eq_filled_white_button {
  border-radius: 0px !important;
  color: #5fc7f1 !important;
  background-color: #fff !important;
  border-color: #fff !important;
}

.eq_filled_white_button:hover {
  background-color: #000 !important;
  color: #fff !important;
  border-color: #000 !important;
}

/* social media buttons */
.eq_social_media_button {
  border-radius: 0px !important;
  border-color: #fff !important;
  background-color: #000 !important;
  color: #fff !important;
}

.eq_social_media_button:hover {
  border-radius: 0px !important;
  color: #5fc7f1 !important;
  background-color: #fff !important;
  border-color: #fff !important;
}

.pointer {
  cursor: pointer;
}

.radius_none {
  border-radius: 0px;
}

.header_color {
  background: var(--dark-grey-color) !important;
}

.eq_grey {
  background: var(--secondry-light-color) !important;
}

.eq_text_color {
  color: var(--primary-light-color) !important;
}

.eq_whitegrey_text_color {
  color: var(--white-grey-color) !important;
}

/* scroll bar  */
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: var(--light-grey-color) !important;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 10px 0px 10px var(--light-color) !important;
  border-radius: 100px;
}

.textarea-control {
  border-radius: 0px !important;
  border: #000 !important;
  width: 100% !important;
  min-height: "100px";
  background-color: #3e3e3e !important;
  color: #fff !important;
}

.bounce {
  display: inline-block;
  animation: float 4s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-15px);
  }

  100% {
    transform: translateY(0);
  }
}

.option {
  /* opacity: 0.7; */
  font-size: 18px;
}

#inputID::placeholder {
  color: white;
  font-size: 18px;
  /* opacity: 0.7;
  -webkit-text-fill-color: #0d9133; */
}

#dob::placeholder {
  color: white;
  font-size: 18px;
  /* opacity: 0.7;
  -webkit-text-fill-color: #0d9133; */
}


.hidden_input {
  display: none;
}

.modal-backdrop {
  background: none;
}

.Form {
  width: 100%;
}

.hashtag_badge_active {
  background-color: var(--primary-light-color);
}

.hashtag_badge_unactive {
  background-color: var(--light-color);
}

.rmsc .item-renderer input:hover {
  -webkit-appearance: auto !important;
}

.rmsc .select-item.selected {
  padding: 10px 10px !important;
}

/* .fb_w {
  font-size: "20px";
  padding: "15px 99px";
}
  */

/* for grid */

/* @media screen and (min-width: 1440px){
  .home_list {
    display: grid;
    grid-auto-columns: repeat(4 , 1fr);
      
  }} */

.playerRatio {
  width: 100%;
  margin-top: 3rem;
}
.video_player {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.flagContent {
  float: right;
  height: 23px;
  line-height: 23px;
  width: 19px;
  text-align: center;
  margin: 0 5px;
  cursor: pointer;
}

.drag.siderfront:before {
  content: '';
  position: absolute;
  height: 100%;
  width: 5px;
  color: transparent;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: e-resize;
}

.tos-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tos-modal-content {
  position: fixed; 
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  background: var(--secondry-light-color)!important; 
  color: rgb(255, 255, 255); 
  padding: 50px 30px 10px;
  z-index: 10000;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.tos-content {
  flex-grow: 1;
  overflow-y: auto;
}

.tos-content p a {
  padding-left: 5px;
}

.tos-buttons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.tos-buttons button {
  cursor: pointer;
  border-color: var(--primary-light-color)!important;
  background: transparent!important;
  background-image: linear-gradient(to bottom,var(--primary-light-color),var(--primary-color))!important;
  border-radius: 0!important;
  margin: calc(var(--bs-modal-footer-gap)*.5);
  color: rgb(255, 255, 255);
  padding: 0.5rem 0.8rem;
  margin-right: 20px;
}

.tos-buttons button:hover {
  background: #000!important;
  color: var(--primary-light-color)!important;
}

.tos-close { 
  position: absolute;
  right: 20px;
  top: 15px;
  font-size: 18px;
  cursor: pointer;
}
.tos-content p:first-child {     
  font-size: 1.25rem;
  border-bottom: 1.5px solid #dee2e6;
  padding-bottom: 15px;
}

.name-input input::placeholder {
  color: #757575 !important;
}

@media only screen and (max-width: 768px) {
  .commentFirst {
    display: block;
  }

  .videoFirst {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .commentFirst {
    display: none;
  }

  .videoFirst {
    display: block;
  }
}

.commentVideoColumn {
  display: flex;
  flex-direction: row;
  width: 100%;
}

@media (max-width: 1024px) and (max-height: 1024px) {
  #Multifileupload .form-control {
    height: 43px!important;
  }
  #Multifileupload .textarea-control {
    height: 55px;
  }
  #Multifileupload .dropzone {
    padding: 0px!important;

  }
  #Multifileupload .eq_button {
    padding: 8px!important;
  }
  #Multifileupload .header {
    height: 0px!important;
  }
  #Multifileupload #main_content {
    min-height: 0px!important;
  }
  .needs-validation .dropzone {
    padding: 10px!important;
    margin-bottom: 10px!important;
  }
}

.upload_modal_section { display: inline-block !important; width: 100% !important; }
.upload-file-list { overflow-y: scroll; height: 46rem; }

@media (max-width:767px) {
    .upload_modal_section { display: flex !important; }
}

@media (max-width:1024px) {
  .upload-file-list { height: 34rem !important;}
  .upload-modal-content .dropdown-heading,
  .edit-video-modal .multi-field .dropdown-container .dropdown-heading { height: 43px!important; }
  .upload-modal-content h5.text-white { font-size: 18px; margin-top: -5px; }
  .upload-modal-content button { font-size: 18px !important; }
  .upload-modal-content { margin-top: 1rem !important; }
  .progress-bar { display: flex; align-items: flex-start; padding-left: 25px; }
  .upload-form-section #inputID, .upload-form-section .dropdown-heading { height: 43px!important; }
  .upload-form-section #Description { height: 65px!important; }
  .upload-form-section button { padding: 12px !important; font-size: 14px !important; }
  .upload-form-section select.form-select { height: 40px !important; }
  .upload-form-section .dropzone p { font-size: 12px !important; }
  .upload-form-section .dropzone { height: 40px; }
  .upload-form-section button { margin-top: 0px !important; }
}

@media only screen and (min-width: 1200px) {
  #leftgridsubscriber {
    display: none !important;
  }

  #rightgridsubscriber {
    display: block !important;
  }
}

@media screen and (min-device-width: 350px) and (max-device-width: 1200px) {
  #normalSidebar {
    display: none !important;
  }

  .canvasSidebar {
    display: block !important;
  }

  #leftgridsubscriber {
    display: block !important;
  }

  #rightgridsubscriber {
    display: none !important;
  }
}

@media screen and (max-width: 1200px) {
  .siderback {
    position: absolute !important;
  }

  .siderbackclick {
    transition: none;
  }

  /* .siderfront{
       position: absolute !important;
      } */
  .siderfrontclick {
    transition: none;
  }
}

@media screen and (max-width: 1300px) {
  .uploader .font-small {
    font-size: 10px !important;
  }
  .uploader .incWidth {
    width: 32% !important;
    padding-left: 20px !important;
  }
}
@media screen and (max-width: 1000px) {
  .uploader .incWidth {
    width: 32% !important;
    padding-left: 20px !important;
  }
}

.backWidth {
  width: 60px;
  max-width: 60px;
}

ul {
  margin: 0;
}

.dropzone {
  text-align: center;
  padding: 20px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;
  margin-bottom: 20px;
}

.accept {
  border-color: #107c10 !important;
}

.reject {
  border-color: #d83b01 !important;
}

#dob::-webkit-datetime-edit-day-field {
  color: #ffffff;
}

#dob::-webkit-datetime-edit-month-field {
  color: #ffffff;
}

#dob::-webkit-datetime-edit-year-field {
  color: #ffffff;
}

.media-img-wrapper {
  width: 3rem;
  height: 3rem;
}

.social-media-img {
  width: 30px;
  height: 30px;
  margin-top: 2px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.social-media-img svg {
  color: #ffffff;
  font-size: 1em;
}

.social-media-img:hover {
  width: 34px;
  height: 34px;
  margin-top: 0px;
  display: flex;
}

.social-media-img:hover svg {
  font-size: 1.5em !important;
}