.invitation_section {
    border: 1px solid #ffffff;
    color: #ffffff;
}

.scroll_elem {
    height: 10rem;
    overflow: auto;
}

/* Target WebKit browsers (Chrome, Safari) for the .scroll_elem class */
.scroll_elem::-webkit-scrollbar {
    width: 15px;
    /* Set the width of the scrollbar track */
}

.scroll_elem::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Set the color of the scrollbar thumb */
    border-radius: 6px;
    /* Set the border radius of the scrollbar thumb */
}

.scroll_elem::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    /* Set the color of the scrollbar thumb on hover */
}

.scroll_elem.invite {
    min-height: 6rem !important;
    height: 100%;
}

.scroll_elem.h-10 {
    height: 10.2rem !important;
}

.scroll_elem.h-12 {
    min-height: 12rem !important;
}

.scroll_elem.h-18 {
    min-height: 18rem !important;
}

.invitation_section .invitation_header {
    padding: 0 10px;
    height: 3.5rem;
    border-bottom: 2px solid;
}

.invitation_section .invitation_header .add_invite_btn {
    padding: 0 10px;
    height: 2rem;
}

.invitation_section .invitation_body {
    color: #ffffff;
    padding-bottom: 3rem;
}

.invitation_section .invitation_body.pb-none {
    padding-bottom: 0 !important;
}

.invitation_section .req_section {
    border-top: 2px solid;
    padding: 11px 16px 0 16px;
}

.invitation_section .invitation_content {
    margin: 10px;
    background-color: #3E3E3E !important;
    border-radius: 11px;
}

.invitation_section .invitation_content .email_text {
    text-overflow: ellipsis;
    overflow: hidden;
}

.invitation_section .invitation_content .invite_select {
    border-radius: 10px;
    color: #000000;
    margin: 10px 0 10px 10px;
}

.invitation_section .invitation_content .remove_btn {
    border-radius: 11px !important;
    height: 30px;
    padding: 0 30px;
    color: #ffffff;
}

.invitation_section .invitation_content .status_ind_icon {
    width: 20px;
    position: absolute;
    bottom: -2px;
    right: -4px;
}

.invitation_section .invitation_content .txt_al_right {
    text-align: right;
}

.invitation_section .invitation_content .more_img_wrapper {
    float: right;
    width: 20px;
    text-align: center;
    background-color: #282828;
    line-height: 65px;
    cursor: pointer;
}

.invitation_section .invitation_content .more_img_wrapper .more_img {
    width: 10px;
    height: 15px;
    margin: 25px 5px;
}

.invitation_section .invitation_content .more_img_wrapper .more_img.transform {
    transform: rotate(90deg);
}

.invitation_section .invitation_content .more_img_wrapper .deleteImg {
    width: 9px;
    height: 9px;
    margin: 23px 5px;
}

.add_invite_modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40rem;
    color: #ffffff;
}

.add_invite_modal.child {
    position: unset;
}

.add_invite_modal .modal-body .tagsinput {
    width: 96%;
    padding: 5px;
    overflow-y: scroll;
}

.add_invite_modal .modal-body .tagsinput .inp_wrapper {
    display: block;
    float: left;
    width: 100%;
}

.add_invite_modal .modal-body .tagsinput .inp_wrapper .add_email_input {
    margin: 0px;
    border: 1px solid transparent;
    padding: 5px;
    background: transparent;
    color: #FFF;
    outline: 0px;
    margin-right: 5px;
    margin-bottom: 5px;
    width: 100%;
}

.add_invite_modal .modal-body .tag {
    border: 1px solid #a5d24a;
    display: block;
    float: left;
    padding: 5px;
    text-decoration: none;
    background: #cde69c;
    color: #638421;
    margin-right: 5px;
    margin-bottom: 5px;
    font-family: helvetica;
    font-size: 13px;
}