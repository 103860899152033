.content_container {
    height: 100%;
    box-sizing: border-box;
    position: relative;
}

.eq_contain {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
}

.header {
    display: block;
    line-height: 25px;
    background: "red";
    background: transparent;
    flex: 0 0 auto;
}

.eq_section {
    flex-direction: row;
    min-height: 100%;
    display: flex;
    flex: auto;
    background: #000;
}

#main_content {
    position: relative;
    width: 0;
    min-height: 100vh;
    display: flex;
    flex: auto;
    flex-direction: column;
}

.eq_header_section {
    padding: 0px;
    height: 25px;
    line-height: 25px;
    width: 100%;
    z-index: 100;
    right: 0px;
    position: fixed;
    top: 0;
    transition: width .3s cubic-bezier(.645, .045, .355, 1);
    flex: 0 0 auto;
}

.eq_main {
    position: relative;
    margin: 24px;
    flex: auto;
    min-height: 0;
    display: block;
}

.eq_content {
    width: 100%;
    padding-top: 100px;
}

.eq_child_content {
    width: 100%;
    padding-top: 30px;
}

.siderbackclick {
    width: 60px;
    overflow: hidden;
    flex: 0 0 60px;
    max-width: 60px;
    min-width: 60px;
    transition: background-color 0.3s ease 0s, min-width 0.3s ease 0s, max-width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

.siderback {
    width: 240px;
    overflow: "hidden";
    flex: 0 0 240px;
    max-width: 240px;
    min-width: 240px;
    transition: background-color 0.3s ease 0s, min-width 0.3s ease 0s, max-width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

.siderfrontclick {
    display: block;
    overflow: hidden;
    padding-top: 120px;
    flex: 0 0 60px;
    max-width: 60px;
    min-Width: 60px;
    width: 60px;
    background-color: #212121;
    position: fixed;
    transition: all .2s;
    border-right: 0;
    top: 0;
    left: 0;
    z-index: 100;
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    box-shadow: 2px 0 8px 0 rgb(29 35 41 / 5%);
}

.siderfrontclick .sider-icon {
    overflow: hidden;
    margin: 0 !important;
}

.siderfrontclick .channel-btn {
    font-size: 9px;
}

.siderfrontclick .column {
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.siderfront {
    display: block;
    overflow: hidden;
    padding-top: 120px;
    flex: 0 0 240px;
    max-width: 240px;
    min-Width: 240px;
    width: 240px;
    background-color: #212121;
    position: fixed;
    transition: all .2s;
    border-right: 0;
    top: 0;
    left: 0;
    z-index: 100;
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    box-shadow: 2px 0 8px 0 rgb(29 35 41 / 5%);
}

.is-sticky {
    position: fixed;
    top: 77px;
    left: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
    background-color: #ffffff !important;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    padding-top: 0px;
    padding-bottom: 0px;
}

#embedModal .embed {
    position: relative;
    border: 0px;
    padding: 0.5em 1em;
    background: none;
    zoom: 1;
}

#embedModal .embed .preview {
    width: 400px;
    height: 135px;
    background-repeat: no-repeat;
    float: left;
    margin: 1px;
    border-radius: 10px;
}

#embedModal .embed .preview iframe {
    border: none;
    width: 250%;
    height: 250%;
    zoom: .4;
    -webkit-transform: scale(0.4);
    -webkit-transform-origin: 0 0;
}

.eq_section .selectAllLink {
    color: var(--bs-link-color) !important;
    text-decoration: underline;
    cursor: pointer;
}

.eq_section .selectAllLink.disable,
.eq_section .name-input.disable,
.content_container .eq_contain .upload_modal_content .disable {
    filter: blur(3px);
    pointer-events: none;
}

.eq_header_section .notify_badge {
    border: 2px solid white;
    border-radius: 20px 20px 20px 20px;
    background-color: red;
    color: white;
    padding: 4px 7px;
    margin: 2px;
    vertical-align: middle;
    text-align: center;
    position: relative;
    top: -9pt;
}

.eq_content .invite_list_wrapper .infinite-scroll-component__outerdiv {
    width: 70% !important;
}

.eq_content .invite_list_wrapper .invitation_list {
    width: 30%;
    border-radius: 10px;
    border-width: 1pt;
    border-color: darkgray;
    border-style: solid;
    padding: 10px;
    height: 100%;
    color: #ffffff;
}

.eq_content .invite_list_wrapper .invitation_list .invitation_list_content {
    border-radius: 0px 50px 50px 0px;
    background-image: linear-gradient(to bottom, #5FC7F1, #4B92CE) !important;
    margin: 5px 0;
    cursor: pointer;
}

#manageLinkModal .manageLinkIcon,
.content_container .eq_section .manageLinkIcon {
    border: 1px solid var(--primary-light-color);
    border-radius: 20px;
    padding: 0px 5px 2px 5px;
    position: relative;
}

#manageLinkModal .manageLinkIcon {
    padding: 3px 9px 8px 9px !important;
}

.content_container .eq_section .manageLinkIcon .linkLockIcon {
    position: absolute;
    left: 17px;
    top: 8px;
}

.content_container .eq_section .dob-custom-placeholder {
    background-color: #3E3E3E !important;
    color: #757575 !important;
    position: absolute;
    padding-top: 16px;
    left: 12px;
    font-size: 18px;
    z-index: 1;
    width: 50%;
    height: 55px;
}

.content_container .eq_section .centered-placeholder::placeholder {
    text-align: center;
}

.apple_logo {
    height: 48px;
    padding-bottom: 6px;
    padding-right: 6px;
}

.apple_store_btn {
    padding: 0px 7px;
    background-image: linear-gradient(to bottom, var(--primary-light-color), var(--primary-color)) !important;
    border-color: var(--primary-light-color) !important;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.storeLabel {
    text-align: center;
    font-size: 1em;
    line-height: normal;
}

.storeName {
    font-size: 1.5em;
    text-align: start;
    font-weight: bold;
    line-height: normal;
}

.app_notification_close {
    position: absolute;
    right: 20px;
    font-size: 18px;
    cursor: pointer;
}

@media only screen and (max-width: 2400px) and (min-height: 780px) and (orientation: landscape) {
    .eq_header_section .videoPlaying {
        position: relative;
    }

    .eq_header_section .videoPlaying .navbar-toggler {
        position: absolute;
        right: 12px;
        top: -44px;
    }

    .eq_header_section .videoPlayingHeader .navbar-toggler {
        margin-right: 22px !important;
    }
}

@media only screen and (max-height: 450px) and (orientation: landscape) {
    .eq_header_section .videoPlaying {
        position: relative;
    }

    .eq_header_section .videoPlaying .navbar-toggler {
        position: absolute;
        right: 12px;
        top: -44px;
    }

    .eq_header_section .videoPlayingHeader .navbar-toggler {
        margin-right: 22px !important;
    }
}

/* Styles for iPhones with smaller screens (up to iPhone 8) */
@media only screen and (max-width: 375px) {
    .apple_logo {
        height: 30px !important;
    }

    .storeLabel,
    .storeName {
        font-size: 1em !important;
    }
}

/* Styles for iPhones with larger screens (iPhone 8 Plus and newer) */
@media only screen and (min-width: 376px) {
    .apple_logo {
        height: 30px !important;
    }

    .storeLabel,
    .storeName {
        font-size: 1em !important;
    }
}