.eq_sidebar_height{
  flex: 1 1 0%;
    overflow: hidden auto;
    display: flex;
    flex-direction: column;
    height: 100%;
}
.channel_list_li{
  border-radius: 0px 50px 50px 0px;
  /* background-image: linear-gradient(to bottom , #5FC7F1, #4B92CE) !important;  */
}

.custom-arrows {
  padding-left: 0px !important;
  padding-right: 0px !important;
  justify-content: center;
}

.nav-link:hover{
  color :#5FC7F1 !important;
}
.active_text:hover{
  color :#fff !important;
}

.active_channel_list_li{
  border-radius: 0px 50px 50px 0px;
  background-image: linear-gradient(to bottom , #5FC7F1, #4B92CE) !important; 
}
.asider_footer{
  width: "100%";
}

.storage_text{
  color: #C4C4C4 !important 
}
.storage_used_text{
  color: #5FC7F1 !important
}
.progress_color{
  background-image: linear-gradient(to bottom , #5FC7F1, #4B92CE) !important;
}
.channel_li_text{
  display: inline-block;
  width: 180px;
  color: #FFF;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.channel_li_text_profile{
  display: inline-block;
  color: #FFF;
  font-size: 18px;
  font-weight: 600;
  
/* 
  text-overflow: ellipsis;
  overflow: hidden; 
  width: 180px; 
    
  white-space: nowrap; */
}
 
.asider_footerclick{
  display: none;
}

.mychannelheadernone{
  display: none;
}

.mychannelheader{
display: none;
}

/* @media screen and (max-width: 350px) {
    .asider_footer{
      display: none;
    }
    
  } */
  
  .normalSidebar{
    background-color: black !important;
  }

.canvasSidebar{
  background-color: black !important;
  width: 286px;

}
  @media screen and (min-device-width:350px ) and (max-device-width:1200px) {
  #normalSidebar{
display:none !important;
  }
  .canvasSidebar{
    display: block !important;
  }

}

  @media screen and (min-device-width:1200px ) {
    .normalSidebar{
  display:block !important;
    }
  .canvasSidebar{
    display: none !important ;
  }
  }
