.navbar{
  background-color: #212121 !important;
}
.search_width{
  max-width: 90% !important;
}
.search-bar{
  max-height: 36px !important;
  background-color: #000 !important;
  color: #FFF !important;
  border-radius: 0px !important;
  border: 0px !important;

}
.search_btn{
  border:  0px solid !important;
  border-radius: 0px !important;
  background-color: #3E3E3E !important;
}
 
#profile_toggle{
  padding: 0px !important;
  border : none !important;
  color: white !important;
}

#profile_toggle:hover{
  padding: 0px !important;
  border : none !important;
  color: white !important;
}
 